import React from "react"
import Page from "../components/page"
import styles from "./shop.module.css"
import Title from "../components/title"
import Contact from "../components/sections/contact"
import Footer from "../components/sections/footer"
import GoToTopButton from "../components/goToTopButton"
import buttonStyles from "../styles/buttons.module.css"

export default function Shop({ location, data }) {
    return (
      <Page title={"Lori Books – Boudoir Photo Albums"} location={location}>

          <div className={styles.main}>

              <Title title='Shop' subtitle='Get you perfect memory'/>

              <div className={styles.content}>


                  <div className={styles.description}>
                      We are currently working really hard to bring you a shopping experience that will match the
                      quality of our albums.
                      <br/>
                      <br/>
                      <p>Meanwhile please order using our Etsy Shop.</p>

                      <div className={styles.order}>
                          <a href="https://www.etsy.com/shop/AlbumHut/" className={styles.button}>
                              <div className={buttonStyles.button}>ETSY SHOP</div>
                          </a>
                      </div>
                  </div>

                  <div className={styles.contact}>
                      <Contact/>
                  </div>


              </div>
              <Footer images={data}/>

              <GoToTopButton/>
          </div>
      </Page>
    )
}

export const query = graphql`
query {
     fbIcon: file(relativePath: { eq: "fb-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    inIcon: file(relativePath: { eq: "in-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    piIcon: file(relativePath: { eq: "pi-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    twIcon: file(relativePath: { eq: "tw-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
   
    
   }
`